import React, { useState, useEffect, useCallback,useRef } from "react";
import { FormGroup, Input, Label, Card ,CardFooter, CardHeader,CardText,CardBody,Button,Row,Col,Spinner} from "reactstrap";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../components/Loading";
import {  useObserver } from "mobx-react";
import { useStores } from "../store/UserActionStore";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faClock } from '@fortawesome/free-solid-svg-icons'
import QotdApiService from "../services/QotdApiService";
import _ from "lodash";
import AnsAlertBox from "../components/AnsAlertBox";
import ExamProgress from "../components/ExamProgress";
import Countdown from 'react-countdown';
import { zeroPad } from 'react-countdown';
import EXAM_TYPE from "../utils/examType";
import ACTION_LIST from "../utils/actionList";
import { useHistory } from "react-router-dom";
import EXAM_LANG from "../utils/examLang";
import QuestionTranslateBox from "../components/QuestionTranslateBox";

const  NO_OF_Q_STUDY= 25

export const ExamPage = () => {
  const history = useHistory();
  const localStore = useStores();
  const currentExamInfo = localStore.getCurrentExamInfo();
  let localUserStatus= localStore.getUserStatus()
  const [state,setState] = useState({isLoading:true,ansBoxEnabled:false,translateBoxEnabled:false,correctAnswered:0,currentQuestionNo:0})
  const {
    getAccessTokenSilently,
  } = useAuth0();
  const endTs = useRef(Date.now() + (localUserStatus.remainExamLength? localUserStatus.remainExamLength : 60000*currentExamInfo.lengthInMinutes) )
  const countdownRef = useRef()

  const fetchData = useCallback(async ()=>{
      const initExamContent = async ()=>{
        // console.log("currentExamInfo",currentExamInfo)
        if (currentExamInfo != null){
          // console.log("initExamContent")
        const token = await getAccessTokenSilently();
        let api = new QotdApiService(token)
        let examContents = await api.getQuestionPool(currentExamInfo.id)
        await localStore.writeExamContentDB(examContents)
        }
      }
      const cachedExamContent = localStore.getCurrentExamContent();
      if (_.isEmpty(cachedExamContent)) { //Todo: exam id validation
         await initExamContent()
      }
      await initExamEnvironement()
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
  // 相似於 componentDidMount 和 componentDidUpdate:
  useEffect(() => {
    fetchData()
  },[fetchData]);

  let totalNoOfQuestions = () => {
    if (localStore.getCurrentExamMode() ===EXAM_TYPE.STUDY_MODE){return NO_OF_Q_STUDY;}
    else{
      return currentExamInfo.questionCount;
    // return  5;
    }
  }
  const initExamEnvironement =()=>{
      let sampledQuestions = localStore.getCurrentExamQuestionList()
      if (_.isEmpty(sampledQuestions)){
        sampledQuestions = _.sampleSize(localStore.getCurrentExamContent(),totalNoOfQuestions())
        localStore.writeCurrentExamQuestionListDB(sampledQuestions)
      }
      let stateContent = {...state, questionLists: sampledQuestions ,  isLoading: false}
      if (localStore.isUserStatusResume()){
        // console.log("resuming datassss")
        stateContent = {...stateContent, ...localUserStatus}
      }
      // console.log("stateContent",stateContent)
      setState( stateContent)
  }
  const selectAns = (i)=>{
    if (state.ansBoxEnabled) return;
    // console.log("selectAns",i)
    setState({...state, currentSelectAns: i })
  }
  const nextQuestion = (nextQuestionNo,isCorrect)=>{
    setState({...state, currentQuestionNo: nextQuestionNo,currentSelectAns:null,ansBoxEnabled: false})
    localStore.writeUserStatus({currentQuestionNo:nextQuestionNo})
  }
  const enableAnsBox = (correct) =>{
    setState({...state,
      ansBoxEnabled: true,
      currentAnsCorrect: correct,
    })
  }
  const enableQuestionTranslateBox = () => {
    setState({
      ...state,
      translateBoxEnabled: !state.translateBoxEnabled,
    });
  }

  const updateAnsweredStatus=async (isCorrect)=>{
    if(isCorrect){setState({...state, correctAnswered: ++state.correctAnswered})}
    let userAnsweredStatus =  ( !_.isEmpty(localUserStatus.currentUserAnswered) ? [...localUserStatus.currentUserAnswered , state.currentSelectAns] : [state.currentSelectAns])
    await  localStore.writeUserStatus({currentUserAnswered:userAnsweredStatus, correctAnswered: state.correctAnswered })
    // console.log("userAnsweredStatus",userAnsweredStatus)

  }

  const submitAns = async ()=>{
    let nextQuestionNo = state.currentQuestionNo + 1
    let currentQuestion = state.questionLists[state.currentQuestionNo]
    let isCorrect = (currentQuestion.answerKeyList[0] === state.currentSelectAns)
    if (!state.ansBoxEnabled && localStore.getCurrentExamMode() ===EXAM_TYPE.STUDY_MODE ){
      await updateAnsweredStatus(isCorrect,state.currentQuestionNo)
      enableAnsBox(isCorrect)
    }else {
      if (localStore.getCurrentExamMode() ===EXAM_TYPE.EXAM_MODE){
        await updateAnsweredStatus(isCorrect,state.currentQuestionNo)
        // console.log("updated")
      }
      if (totalNoOfQuestions() ===nextQuestionNo){
        submitExam(nextQuestionNo); return;
      }
        nextQuestion(nextQuestionNo,isCorrect)
    }
  }
  const submitExam = (nextQuestionNo)=>{
    // console.log(totalNoOfQuestions())
    countdownRef.current.api.stop();
    const completedExamInfo = {userAnsStatus: localStore.getUserStatus(), examQuestionList: localStore.getCurrentExamQuestionList(), examLang: localUserStatus.examLang } ;
    localStore.writeExamResultDB(completedExamInfo)
    localStore.setAction(ACTION_LIST.EXAM_END,completedExamInfo)
    // console.log("Exam Ends");
    history.push('/score_report')
  }
  return useObserver(()=> {
    return <>
      <div className="mb-5">
        <Card>
          <CardHeader>
            <h3>{currentExamInfo.name}</h3>
          </CardHeader>
          {(state.isLoading)?
            <CardBody     className="text-center" >
              <h3>Loading Questions</h3>
              <Spinner>
                Loading...
              </Spinner>
            </CardBody>:
            <CardBody>
              <FormGroup tag="fieldset">
                <CardText className={"question-text"}>
                 {localUserStatus.examLang ===EXAM_LANG.ENGLISH? (state.questionLists[state.currentQuestionNo].questionSentence) : ""}
                 {localUserStatus.examLang ===EXAM_LANG.TC_CHINESE? (state.questionLists[state.currentQuestionNo].questionSentenceTW) : ""}
                </CardText>
                <Col>
                  {localUserStatus.examLang ===EXAM_LANG.ENGLISH?
                    (state.questionLists[state.currentQuestionNo].options.map((option, i) =>
                      <FormGroup key={i} onClick={()=>{selectAns(i)}}  >
                        <Input
                          onChange={()=>{selectAns(i)}}
                          checked={ i ===state.currentSelectAns}
                          name="{i}"
                          type="radio"
                          disabled={state.ansBoxEnabled}
                        />
                        {' '}
                        <Label>
                          {option}
                        </Label>
                      </FormGroup>
                    )): ""
                  }
                  {localUserStatus.examLang ===EXAM_LANG.TC_CHINESE?
                    (state.questionLists[state.currentQuestionNo].optionsTW.map((option, i) =>
                      <FormGroup key={i} onClick={()=>{selectAns(i)}}  >
                        <Input
                          onChange={()=>{selectAns(i)}}
                          checked={ i ===state.currentSelectAns}
                          name="{i}"
                          type="radio"
                          disabled={state.ansBoxEnabled}
                        />
                        {' '}
                        <Label>
                          {option}
                        </Label>
                      </FormGroup>
                    )): ""
                  }
                </Col>
              </FormGroup>
              <QuestionTranslateBox isOpen={state.translateBoxEnabled} currentQuestion={state.questionLists[state.currentQuestionNo]}  examLang={localUserStatus.examLang}/>
              <Button  color="success" onClick={submitAns}  disabled={state.currentSelectAns ===null }>
                {state.ansBoxEnabled? "Next": "Submit"}
              </Button>{" "}
              <Button onClick={enableQuestionTranslateBox}    color="primary">
                Translate
              </Button>
                <AnsAlertBox isOpen={state.ansBoxEnabled} currentQuestion={state.questionLists[state.currentQuestionNo]} correct={state.currentAnsCorrect} examLang={localUserStatus.examLang}/>
            </CardBody>

          }

          <CardFooter>
            <Row>
              <Col  xs="auto"
              >
                <FontAwesomeIcon icon={faClock} />
                &nbsp;
                <Countdown
                  ref={countdownRef}
                  date = { endTs.current }
                precision={2}      onTick={(delta)=>{localStore.writeUserStatus({ remainExamLength: delta.total})}}
                renderer={({ hours, minutes, seconds }) => (
                  <>
                    {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
                  </>
                )}
                />
              </Col>
              <Col xs="6"
              >
                <ExamProgress currentQuestionNo={state.currentQuestionNo+1} totalNoQuestions={totalNoOfQuestions()}/>
              </Col>
              <Col
                xs="3"
              >
                {(localStore.getCurrentExamMode() ===EXAM_TYPE.STUDY_MODE)? `Score: ${Math.round(state.correctAnswered/totalNoOfQuestions()*100)} %`: ""}

              </Col>
            </Row>
          </CardFooter>
        </Card>
      </div>
    </>
  })
};

export default withAuthenticationRequired(ExamPage, {
  onRedirecting: () => <Loading />,
});
