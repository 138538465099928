import React from 'react';
import { Alert} from "reactstrap";
import EXAM_LANG from "../utils/examLang";

const QuestionTranslateBox = (props) => {
  const currentQuestion = props.currentQuestion
  // // console.log(currentQuestion)
  return (props.isOpen?
    <>
      <Alert className={"question-translate-box"}
        color={'success'} isOpen={props.isOpen}
      >
        <p>{(props.examLang === EXAM_LANG.TC_CHINESE)?currentQuestion.questionSentence: currentQuestion.questionSentenceTW}</p>

        {(props.examLang === EXAM_LANG.TC_CHINESE)? currentQuestion.options.map((option, i) =><li key={i}>{option}</li>): currentQuestion.optionsTW.map((option, i) =><li key={i}>{option}</li>)}
      </Alert>
      <hr />
    </>:""
  );
};
export default QuestionTranslateBox