import React from "react";
import loading from "../assets/loading.svg";

const Unauthorized = () => (
  <>
    <h5>Executive for wuson's students, please join </h5>
    <a href={"https://wentzwu.com/courses/"}>WUSON Private School</a>
  </>
);

export default Unauthorized;
