import { computed,makeAutoObservable} from "mobx";
import {createContext,useContext} from "react";
import { LowSync } from 'lowdb/lib/LowSync'
import { LocalStorage } from 'lowdb/lib/adapters/LocalStorage'
import _ from 'lodash';
import EXAM_TYPE from "../utils/examType";

class UserActionStore {
    selectedTestID = 0;
    action = "NONE";
    actionObject = "" // JSON String
    examListDB = new LowSync(new LocalStorage('qotd_info_db'))
    userStatusDB = new LowSync(new LocalStorage('qotd_user_status'))
    currentExamContentDB = new LowSync(new LocalStorage('current_exam_content'))
    currentExamQuestionListDB = new LowSync(new LocalStorage('current_exam_question_list'))
    examResultDB = new LowSync(new LocalStorage('exam_result'))

    constructor() {
        makeAutoObservable(this);
        this.examListDB.read()
        this.userStatusDB.read()
        this.currentExamContentDB.read()
        this.currentExamQuestionListDB.read()
        this.examResultDB.read()
    }
    async selectTest(testId, examLang){
        await this.flushCurrentExamInfo();
        // // console.log('selectTest(testId,examLang)',testId,examLang)
        this.selectedTestID = testId
        this.writeUserStatus({currentTest: testId, examLang: examLang})
    }
    writeUserStatus = async (userStatus)=>{
        this.userStatusDB.read()
        this.userStatusDB.data = {...this.userStatusDB.data, ...userStatus}
        this.userStatusDB.write()
    }
    getUserStatus =  ()=>{
        this.userStatusDB.read()
        // // console.log("getUserStatus", this.userStatusDB.data)
        return this.userStatusDB.data
    }
    writeExamResultDB = ( data )=>{
        this.examResultDB.data = data
        this.examResultDB.write()
    }
    getExamResultDB = ()=>{
        this.examResultDB.read()
        return this.examResultDB.data
    }
    isUserStatusResume = ()=>{
        this.userStatusDB.read()
        let data =this.userStatusDB.data
        return (
          data.currentQuestionNo>0
          && data.currentTest != null
          && !_.isEmpty(data.currentUserAnswered)
        )
    }
    writeExamListDB = async (examLists)=>{
        this.examListDB.data = examLists
        this.examListDB.write()
    }
    writeExamContentDB = async (examContent)=>{
        this.currentExamContentDB.data = examContent
        this.currentExamContentDB.write()
    }
    writeCurrentExamQuestionListDB = async (examContent)=>{
        this.currentExamQuestionListDB.data = examContent
        this.currentExamQuestionListDB.write()
    }
    flushCurrentExamInfo =async  ()=>{
        this.userStatusDB.data = {}
        this.currentExamContentDB.data = []
        this.currentExamQuestionListDB.data = []
        await this.userStatusDB.write()
        await this.currentExamContentDB.write()
        await this.currentExamQuestionListDB.write()
    }

    getExamInfo =  (examID)=>{
        return  _.find(this.examListDB.data,{id: examID})
    }
    getCurrentExamInfo =  ()=>{
        return  _.find(this.examListDB.data,{id: this.userStatusDB.data.currentTest})
    }
    getCurrentExamMode = ()=>{
        if(this.getCurrentExamInfo()){
            if ([0,1].includes(this.getCurrentExamInfo().mode)){return EXAM_TYPE.STUDY_MODE}
            else{
                return EXAM_TYPE.EXAM_MODE
            }
        }
    }
    getCurrentExamContent =  ()=>{
        this.currentExamContentDB.read()
        return  this.currentExamContentDB.data
    }
    getCurrentExamQuestionList =  ()=>{
        this.currentExamQuestionListDB.read()
        return  this.currentExamQuestionListDB.data
    }
    @computed
    getActionObject = ()=> {
      return Object.assign({},JSON.parse(this.actionObject));
    }

    @computed
    setAction(action=null, actionObject= {}){
        // // console.log("setAction",action);
        // console.log("actionObject",actionObject);
        this.action= action;
        this.actionObject = JSON.stringify(actionObject)
    }
    @computed
    cleanAction(){
        this.action= "NONE";
        // this.actionObject = ""
    }
}

const StoresContext = createContext(new UserActionStore());
// this will be the function available for the app to connect to the stores
export const useStores = () => useContext(StoresContext);