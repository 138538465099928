import axios from "axios";
const apiOrigin = "https://wusontestengineservicedocker.azurewebsites.net";
class QotdApiService  {
 constructor(bearerToken){
    this.token = bearerToken;
    this.apiInstance = axios.create({
         baseURL: `${apiOrigin}`,
         headers: {'Authorization': `Bearer ${this.token}`}
     });
 }
    getExams = async ()=> ((await this.apiInstance.get(`${apiOrigin}/exam`)).data);
    getQuestionPool = async (examIds)=> ((await this.apiInstance.get(`${apiOrigin}/question/pool/${examIds}`)).data);
}

export default QotdApiService;
