import React   from 'react';
import { Alert} from "reactstrap";
import EXAM_LANG from "../utils/examLang";
const AnsAlertBox = (props) => {
  return (props.isOpen?
    <>
      <hr />
      <Alert
        color={props.correct? 'success': 'danger'} isOpen={props.isOpen}
      >
        <p><b>{props.correct? 'Correct': 'Incorrect'}</b></p>
        <p>My suggested answer is {(props.examLang === EXAM_LANG.ENGLISH)?props.currentQuestion.suggestedAnswer: props.currentQuestion.suggestedAnswerTW}</p>
        <p>Please click the following like for my justification:</p>
        <a href={props.currentQuestion.explanationLink}>{props.currentQuestion.explanationLink}</a>
      </Alert>
    </>:""
  );
};
export default AnsAlertBox