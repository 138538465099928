import React  from 'react';
import { CardText,CardBody,Alert} from "reactstrap";
import EXAM_LANG from "../utils/examLang";

const QuestionAnswerDescription = (props) => {
  // // // console.log(props)
  const question = props.question
  const questionKey = props.no
  const correct = (props.userAnswer === question.answerKeyList[0])
  const examLang = props.examLang
  return (<CardBody className={"report-ans"}>
    <CardText className={"question-text"}>
      {questionKey+1}. {examLang === EXAM_LANG.ENGLISH ? question.questionSentence : question.questionSentenceTW}
    </CardText>
    <CardText className={'report-ans-list'}>
      {examLang === EXAM_LANG.ENGLISH ? question.options.map((opt,i)=>(<li key={i}>{opt}</li>)) : question.optionsTW.map((opt,i)=>(<li key={i}>{opt}</li>))}
    </CardText>

    <Alert className={'report-ans-box'}
      color={correct? 'success': 'danger'}
    >
      <p>Your Answer is: <b className={correct? "correct": "wrong"}>{examLang === EXAM_LANG.ENGLISH ? question.options[props.userAnswer] : question.optionsTW[props.userAnswer]}</b></p>
      <p>My suggested answer is {examLang === EXAM_LANG.ENGLISH ? question.suggestedAnswer : question.suggestedAnswerTW }</p>
      <p>Please click the following like for my justification:</p>
      <a href={question.explanationLink}>{question.explanationLink}</a>
    </Alert>
    <hr />
  </CardBody>)
};
export default QuestionAnswerDescription