import React from "react";
import { CardBody, Spinner } from "reactstrap";

const LoadingCard = (props) => (
  <CardBody     className="text-center" >
    <h3>Loading {props.loadingItem}</h3>
    <Spinner>
      Loading...
    </Spinner>
  </CardBody>
)
export default LoadingCard;
