import React, { Fragment, useEffect, useCallback, setState, useState } from "react";

import Hero from "../components/Hero";
import Content from "../components/Content";
import { useAuth0 } from "@auth0/auth0-react";
import jwtDecode from "jwt-decode";
import QotdApiService from "../services/QotdApiService";
import _ from "lodash";
import Unauthorized from "../components/Unauthorized";


const Home = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  const [unauthorized, setUnauthorized] = useState(false)
  const fetchData = useCallback(async ()=>{
      const initPage = async ()=>{
         let token = await getAccessTokenSilently();
         console.log(jwtDecode(token).scope)
          if (!jwtDecode(token).scope.includes("wuson:students")){
              setUnauthorized(true)
          }
        }
      if (user)
        initPage()
      },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
  // 相似於 componentDidMount 和 componentDidUpdate:
  useEffect(() => {
    fetchData()
  },[fetchData]);
  return (<Fragment>
    <Hero/>
    <hr/>
    {(user && !unauthorized )? <Content/> : ""}
    {(unauthorized )? <Unauthorized/> : ""}
  </Fragment>);
};

export default Home;
