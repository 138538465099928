import React, { useState, useEffect } from "react";
import { CardHeader, Card, CardBody, CardTitle, CardSubtitle } from "reactstrap";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../components/Loading";
import LoadingCard from "../components/LoadingCard"
import {  useObserver } from "mobx-react";
import { useStores } from "../store/UserActionStore";
import ACTION_LIST from '../utils/actionList'
import { when } from "mobx";
import QuestionAnswerDescription from "../components/QuestionAnswerDescription";
import convertMiliseconds from "../utils/convertMs"
import { zeroPad } from "react-countdown";
import noOfCorrect from "../utils/noOfCorrect";
import EXAM_LANG from "../utils/examLang";
import jwtDecode from "jwt-decode";
import Unauthorized from "../components/Unauthorized";

export const ScoreReportComponent = () => {
  const localStore = useStores();
  const [examResult,setExamResult] = useState({});
  const [state, setState] = useState({ isLoading: true, emptyReport: false,  unauthorized: false });
  const {
    getAccessTokenSilently
  } = useAuth0();
  useEffect(() => {
    const compRes = async ()=>{
      const token = await getAccessTokenSilently();

      if (!jwtDecode(token).scope.includes("wuson:students")){
        setState({
          ...state,
          unauthorized: true
        }); return;
      }

      let examResult = await localStore.getExamResultDB()
      if (examResult == null){
        await setState({...state, emptyReport: true})
        return
      }
      let { userAnsStatus } = examResult
      let examInfo = localStore.getExamInfo(userAnsStatus.currentTest)
      let espTime =convertMiliseconds(60000 * examInfo.lengthInMinutes - examResult.userAnsStatus.remainExamLength )
      let correctInExam = noOfCorrect(examResult.userAnsStatus.currentUserAnswered, examResult.examQuestionList)
      examResult = {...examResult, examInfo,espTime,correctInExam}
      // console.log("examResult",examResult)
      await setExamResult( examResult) // Test QA Result
      await setState({...state, isLoading: false})
    }
    compRes()
  },[]);

  when(()=> localStore.action===ACTION_LIST.EXAM_END,
    async ()=>{
      // console.log(localStore.getActionObject())
    });

  return useObserver(()=> (
    (state.unauthorized)?
    <Unauthorized/>:<>
    <div className="mb-5">
      <h1>Score Report</h1>
      <div>
        {
          (state.isLoading)?
            ((state.emptyReport)? <p>Exam Record is empty,please complete some examination.</p> : <LoadingCard loadingItem="result" />):
            <Card>
              <CardHeader>
                <CardTitle tag="h5">
                  {examResult.examInfo.name}
                </CardTitle>
                <CardSubtitle
                  className="mb-2 text-muted"
                  tag="h6"
                >
                  Language: {examResult.examInfo.examLang === EXAM_LANG.ENGLISH? "English" : "Traditional Chinese"}
                </CardSubtitle>
                <CardSubtitle
                  className="mb-2 text-muted"
                  tag="h6"
                >
                  Elapsed time{" "}
                    {zeroPad(examResult.espTime.h)}:{zeroPad(examResult.espTime.m)}:{zeroPad(examResult.espTime.s)}
                </CardSubtitle>
                <CardSubtitle   className="mb-2 text-muted"
                                tag="h6">
                  Score  {examResult.correctInExam/examResult.examQuestionList.length*100}% ({examResult.correctInExam}/{examResult.examQuestionList.length})
                </CardSubtitle>
              </CardHeader>
              <CardBody>
                {examResult.examQuestionList.map((question,i)=> <QuestionAnswerDescription examLang={examResult.examLang} key={i} no={i} question={question} userAnswer={examResult.userAnsStatus.currentUserAnswered[i]} />)}
              </CardBody>
            </Card>
        }
      </div>

    </div>
  </>))
};

export default withAuthenticationRequired(ScoreReportComponent, {
  onRedirecting: () => <Loading />,
});
