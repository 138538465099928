export default function noOfCorrect(currentUserAnswered, examQuestionList) {
  // console.log(currentUserAnswered, examQuestionList)
  let noOfCorrect= 0;
  examQuestionList.forEach((q,i)=>{
    if (currentUserAnswered[i] === q.answerKeyList[0]){
      noOfCorrect++
    }
  })
  return noOfCorrect
};
