import React, { Component } from "react";

import { Link } from "react-router-dom";
import { Button } from "reactstrap";


class Content extends Component {
  render() {
    return (
      <div className="next-steps my-5 text-center">
        <h2 className="text-center">What can I do next?</h2>
        <Link
          to="exam/select"
        >
          <Button
            color="success"
          >
            <b> Select Examination</b>
          </Button>
        </Link>
      </div>
    );
  }
}

export default Content;
