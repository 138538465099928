import React, { useState,useEffect} from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Table } from "reactstrap";
import {  when } from "mobx";
import ACTION_LIST from '../utils/actionList'
import { useStores } from "../store/UserActionStore";

const ControlledPopup = () => {
  const localStore = useStores();
  let mounted = false
  const [state, setState] = useState({
    isOpen: false,
  });
  const [popupContent, setPopupContent] = useState({})
  const openModel = () => setState({
    ...state,
    isOpen: true
  });
  const closeModel = () => setState({
    ...state,
    isOpen: false
  });
  const startExam = () =>{
    closeModel()
    localStore.setAction(ACTION_LIST.EXAM_STARTED , {examId:popupContent.id, examLang: popupContent.examLang})
  }
  useEffect(() => {
    mounted=true
    return ()=>{mounted=false}
  });

  when(()=> localStore.action===ACTION_LIST.EXAM_CONFIRM,
    ()=>{
    if(mounted){
      setPopupContent(localStore.getActionObject)
      openModel();
      localStore.cleanAction()
    }
  })
  return (
    <div>
      <Modal
        isOpen={state.isOpen}
        toggle={closeModel}
      >
        <ModalHeader toggle={closeModel}>
          {popupContent.name}
        </ModalHeader>
        <ModalBody>
          <Table bordered>
            <tbody>
              <tr>
                <th>Exam Name:</th>
                <td>{popupContent.name}</td>
              </tr>
              <tr>
                <th>Exam Language:</th>
                <td>{popupContent.lang === 'en'? 'English':'Traditional Chinese'}</td>
              </tr>
              <tr>
                <th>Exam Length:</th>
                <td>{popupContent.lengthInMinutes} minute(s)</td>
              </tr>
              <tr>
                <th>Max Attempts:</th>
                <td>{popupContent.attemptLimit}</td>
              </tr>
              <tr>
                <th>Score Report:</th>
                <td>Yes</td>
              </tr>
              <tr>
                <th>Question Mode:</th>
                <td>{(popupContent.randomizeQuestions)?"Random":"Sequential "}</td>
              </tr>
            </tbody>
          </Table>
          {
            ([0,1].includes(popupContent.mode))?
              <>
                {popupContent.name} has {popupContent.questionCount} questions in total, which can exercise with Tomato
                Clock in {popupContent.lengthInMinutes} minute(s)
              </>:
              <>
                {popupContent.name} simulates the real exam. The answer will not be provided after each question answered.
              </>
          }
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={startExam}
          >
            Start Exam
          </Button>
          {' '}
          <Button onClick={closeModel}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};
export default ControlledPopup