import React from 'react';
import {  Progress } from "reactstrap";

const ExamProgress = (props) => {
  let percentage = Math.round(props.currentQuestionNo/props.totalNoQuestions*100)
  return   <Progress
    animated
    color="success"
    value={percentage}
  >
    {props.currentQuestionNo} of {props.totalNoQuestions} questions
  </Progress>
};
export default ExamProgress