import React, { useState, useEffect, useCallback } from "react";
import { Button, ButtonGroup,Table} from "reactstrap";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import Loading from "../components/Loading";
import ControlledPopup from "../components/Popup";
import QotdApiService from "../services/QotdApiService";
import {  useObserver } from "mobx-react";
import { useStores } from "../store/UserActionStore";
import ACTION_LIST from '../utils/actionList'
import _ from 'lodash';
import { when } from "mobx";
import { useHistory } from "react-router-dom";
import LoadingCard from "../components/LoadingCard";
import EXAM_LANG from "../utils/examLang";
import jwtDecode from "jwt-decode";
import Unauthorized from "../components/Unauthorized";
export const SelectExamComponent = () => {
  const history = useHistory();
  const localStore = useStores();
  const [state, setState] = useState({
    error: null,
    data: [],
    selectedTestId:0,
    selectedExamLanguage: EXAM_LANG.ENGLISH,
    isLoading: true,
    unauthorized: false
  });
  const {
    getAccessTokenSilently,user,getIdTokenClaims
  } = useAuth0();
  const fetchData = useCallback(()=>{
      const initApiService = async ()=>{
        const token = await getAccessTokenSilently();
        if (!jwtDecode(token).scope.includes("wuson:students")){
          setState({
            ...state,
            unauthorized: true
          }); return;
        }
        let api = new QotdApiService(token)
        let examDatas = await api.getExams()
        await localStore.writeExamListDB(examDatas)
        setState({
          ...state,
          data: examDatas,
          isLoading: false
        });
      }
      initApiService();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []);
  // 相似於 componentDidMount 和 componentDidUpdate:
  useEffect(() => {
    fetchData()
  },[fetchData]);

  const confirmExam =()=> {
    if (state.selectedTestId>0){
      let selectedExamInfos = _.find(state.data,{id: state.selectedTestId})
      localStore.setAction(ACTION_LIST.EXAM_CONFIRM, { ...selectedExamInfos, examLang: state.selectedExamLanguage })
    }
  }
  when(()=> localStore.action===ACTION_LIST.EXAM_STARTED,
    async ()=>{
      localStore.cleanAction()
      await localStore.selectTest(localStore.getActionObject().examId, localStore.getActionObject().examLang)
      history.push('/exam')
    })

  return useObserver(()=> (
    (state.unauthorized)?
    <Unauthorized/>:<>
    <div className="mb-5">
      <h1>Select Exam </h1>
      <p className="lead">
        Please select an exam.
      </p>
      {(state.isLoading)?
        <LoadingCard loadingItem="result" />:
        <>
          <Table hover>
            <thead>
            <tr>
              <th>
                ID
              </th>
              <th>
                Name
              </th>
              <th>
                No. of questions
              </th>
              <th>
                Time Limit
              </th>
            </tr>
            </thead>
            <tbody>
            {state.data.map(exam => (
              <tr className={(state.selectedTestId === exam.id)?"table-primary":""}
                  onClick={()=>setState({...state,selectedTestId: exam.id})}
                  key={exam.id}
              >
                <th scope="row">
                  {exam.id}
                </th>
                <td>
                  {exam.name}
                </td>
                <td>
                  {exam.questionCount}
                </td>
                <td>
                  {exam.lengthInMinutes} minutes
                </td>
              </tr>
            ))}
            </tbody>
          </Table>
          <div>
          <h6>
            Exam Language
          </h6>
          <ButtonGroup>
            <Button
              color="success"
              active={state.selectedExamLanguage === EXAM_LANG.ENGLISH}
              onClick={()=>setState({...state,selectedExamLanguage: EXAM_LANG.ENGLISH})}
            >
              English
            </Button>
            <Button
              color="success"
              active={state.selectedExamLanguage === EXAM_LANG.TC_CHINESE}
              onClick={()=>setState({...state,selectedExamLanguage: EXAM_LANG.TC_CHINESE})}
            >
              Chinese
            </Button>
          </ButtonGroup>
          </div>
          <Button
            color="primary"
            className="mt-5"
            onClick={confirmExam}
          >
            Select
          </Button>
        </>}
      <ControlledPopup/>
    </div>
  </>)
  )
};

export default withAuthenticationRequired(SelectExamComponent, {
  onRedirecting: () => <Loading />,
});
