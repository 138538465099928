import React from "react";

const Footer = () => (
  <footer className="bg-light p-3 text-center">
    <p>
      QOTD provided by <a href="https://wentzwu.com">Wentz Wu</a>
    </p>
    Web edition developed by <a href={"https://wentzwu.com/2021/10/05/cissp%e8%80%83%e8%a9%a6%e5%bf%83%e5%be%97-kelvin/"}>Kelvin Tam</a>
  </footer>
);

export default Footer;
